module directives {
    export module applicationcore {
        interface ILookupScope extends ng.IScope {
            code: string,
            description: string,
            id: number,
            openLookup(): void,
            codeChanged(code: string): void,
            change(any): void,
            lookupConfig: interfaces.applicationcore.ILookupDirective,
            isBusy: boolean
        }

        export class lookupDirective implements ng.IDirective {
            template = `<p class="input-group input-group-sm has-feedback" ng-class="{'has-error': form[name].$invalid}">
                              <input name="{{name}}" type="text" ng-model="code" ng-change="codeChanged(code)" ng-disabled="isBusy || ngDisabled" class="form-control" ng-required='required' ng-model-options="{ updateOn: 'blur' }" />
                              <span class="input-group-btn">
                                <button class="btn btn-primary" type="button" ng-click="openLookup()" ng-disabled="ngDisabled"><i class="fa fa-ellipsis-h"></i></button>
                              </span>
                              <input type="text" ng-model="description" class="form-control" disabled />
                            </p>
                        `;
            scope = {
                id: "=",
                code: "=",
                description: "=",
                required: "=",
                lookupConfig: "=",
                form: "=",
                name: '@',
                change: '&',
                ngDisabled: '='
            }

            constructor(public $uibModal: ng.ui.bootstrap.IModalService,
                private skuService: interfaces.master.ISKUService,
                private generalService: interfaces.applicationcore.IGeneralService) {
            }

            link = ($scope: ILookupScope, $element: ng.IAugmentedJQuery) => {
                //$scope.isBusy = false;
                $scope.openLookup = () => {
                    this.$uibModal.open({
                        animation: true,
                        templateUrl: 'templates/modules/applicationcore/lookupView.html',
                        controller: 'lookupCtrl',
                        controllerAs: 'LookupCtrl',
                        resolve: {
                            items: function () {
                                return $scope.lookupConfig;
                            }
                        }
                    }).result.then((data: interfaces.applicationcore.ILookupResponse) => {
                        $scope.id = data.id;
                        $scope.code = data.code;
                        $scope.description = data.description;
                        $scope.change({ Id: data.id });

                        }, (data) => {
                        });
                }

                $scope.codeChanged = (code: string) => {
                    $scope.change({ code: code });
                }
            }


            static factory(): ng.IDirectiveFactory {
                const directive = ($uibModal, skuService, generalService) => new lookupDirective($uibModal, skuService, generalService);
                directive.$inject = ['$uibModal', 'skuService', 'generalService'];

                return directive;
            }
        }

        angular.module("app").directive("gtsLookup", lookupDirective.factory());
    }
}